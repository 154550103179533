<template>
  <component :is="layout">
    <template v-slot:title>Account Settings</template>

    <div class="p-0 p-md-3 bg-gray-light-4 overflow-auto h-100">
      <div class="row">
        <!-- Cards -->

        <div class="col-12 col-md-8 offset-md-2">
          <GoBackBtn :url="{ name: 'Dashboard' }">Back</GoBackBtn>

          <AppCard class="mb-4">
            <div class="p-3">
              <h2
                class="
                  m-0
                  mb-3
                  text-uppercase text-headline-5 text-md-headline-2
                "
              >
                Change Password
              </h2>

              <AppInputForm
                v-model="formValid"
                @validate="changePassword"
                v-slot="{ validate }"
              >
                <AppInput
                  label="Current Password"
                  v-model="form.current_password"
                  :disabled="loading"
                  :validations="[validation.required()]"
                  :validate="validate"
                  type="password"
                  v-if="!$route.params.id"
                />

                <AppInput
                  label="New Password"
                  v-model="form.new_password"
                  :disabled="loading"
                  :validations="[validation.required()]"
                  :validate="validate"
                  type="password"
                />

                <AppInput
                  label="Confirm New Password"
                  v-model="form.new_password_confirmation"
                  :disabled="loading"
                  :validations="[
                    validation.required(),

                    validation.matchString(form.new_password, 'New Password'),
                  ]"
                  :validate="validate"
                  type="password"
                />

                <AppBtn
                  class="mr-0 mt-4 ml-auto d-block"
                  type="submit"
                  :loading="loading"
                  :disabled="loading"
                >
                  Confirm New Password
                </AppBtn>
              </AppInputForm>
            </div>
          </AppCard>
        </div>
      </div>
    </div>

    <ActionModal
      v-model="changePasswordModal"
      state="delete"
      @confirm="changePassword"
    >
      <template v-slot:title>
        Are you sure you want to change your password?
      </template>

      <template v-slot:description> Click "Confirm" to proceed. </template>
    </ActionModal>

    <SuccessModal
      v-model="changePasswordModalSuccess"
      @confirm="$router.back()"
    >
      <template v-slot:title> Password Updated! </template>

      <template v-slot:description></template>
    </SuccessModal>
  </component>
</template>

<script>
/* eslint-disable */
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppInputForm from '@/shared/elements/AppInputForm.vue';
import AppCard from '@/shared/elements/AppCard.vue';
import AppRowInputContainer from '@/shared/elements/AppRowInputContainer.vue';
import AppBtn from '../../../shared/elements/AppBtn.vue';
import PersonaConstants from '@/shared/constants/PersonaConstants';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import validation from '@/shared/utils/validation';
import { mapGetters, mapMutations } from 'vuex';
import { updateUser } from '@/shared/api/Users.js';
import _appApiHelper from '@/shared/mixins/_appApiHelper';

const INITIAL_FORM_STATE = {
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
};

export default {
  mixins: [_appApiHelper],

  components: {
    GoBackBtn,
    AppCard,
    AppInput,
    AppBtn,
    AppRowInputContainer,
    ActionModal,
    SuccessModal,
    AppInputForm,
    LayoutSuper: () =>
      import('@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin'),
    LayoutVendor: () =>
      import('@/components/Partial/Layouts/LayoutVendor/LayoutVendor'),
    LayoutBuyer: () =>
      import('@/components/Partial/Layouts/LayoutBuyer/LayoutBuyer'),
  },

  data() {
    return {
      validation,
      formValid: false,
      changePasswordModal: false,
      changePasswordModalSuccess: false,
      form: { ...INITIAL_FORM_STATE },
    };
  },

  computed: {
    ...mapGetters('auth', ['user']),
    layout() {
      const { persona } = this.user || '';
      let layoutName = 'LayoutBuyer';

      switch (persona) {
        case PersonaConstants.super.value:
          layoutName = 'LayoutSuper';
          break;
        case PersonaConstants.vendor.value:
          layoutName = 'LayoutVendor';
          break;
        case PersonaConstants.buyer.value:
          layoutName = 'LayoutBuyer';
          break;
        default:
          layoutName = 'LayoutBuyer';
          break;
      }

      return layoutName;
    },
  },

  methods: {
    ...mapMutations('api', {
      setPreventSuccess: 'PREVENT_SUCCESS_DEFAULT',
    }),

    askChangePassword() {
      this.changePasswordModal = true;
    },

    changePassword() {
      let id = this.user.id;

      if (this.$route.params.id) {
        id = this.$route.params.id;
      }

      const formData = {
        password: this.form.current_password,
        new_password: this.form.new_password,
        new_password_confirmation: this.form.new_password_confirmation,
        _method: 'PUT',
      };

      if (this.$route.params.id) {
        formData.password = this.form.new_password;
      }

      this.setPreventSuccess(true);
      if (!this.formValid) {
        return false;
      }

      this.loading = true;

      updateUser(id, formData)
        .then(() => {
          //If success then show modal
          this.changePasswordModalSuccess = true;

          this.form = { ...INITIAL_FORM_STATE };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
